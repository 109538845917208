<template lang="pug">
v-dialog( :value="true"  max-width="700px" @input="$emit('close')")
  v-card
    v-card-title.d-flex.justify-space-between
      span {{ copyId ? 'Копировать аккаунт' :'Добавить аккаунт'}}
      v-btn(
        icon
        @click="$emit('close')"
      )
        v-icon fa-times

    v-card-text
      v-select(
        label="Роль"
        v-model="account.roleId"
        :items="roles"
        item-value="id"
        item-text="name"
        :error-messages="getValidationErrors('account.roleId')"
        @blur="$v.account.roleId.$touch()"
        :disabled="isLoading"
      )

      v-text-field(
        v-model="account.email"
        label="Email"
        :error-messages="getValidationErrors('account.email')"
        @blur="$v.account.email.$touch()"
        :disabled="isLoading"
      )

      EditProfile(v-model="account.profile" ref="form" :disabled="isLoading")

    v-card-title.py-0 Привязки
    v-card-text.pb-0
      Spinner(v-if="isLoadingBindings")
      AccountBindings(v-else v-model="account.bindings")

    v-card-text

      v-row(align-end)
        v-col(cols="12" sm="6")
          v-btn(
            @click="$emit('close')"
            outlined
            block
            :disabled="isLoading"
          ) Отмена
        v-col(cols="12" sm="6")
          v-btn(
            @click="submit"
            block
            depressed
            color="primary"
            :loading="isLoading"
          ) Сохранить

</template>

<script>
import AccountBindings from '@/views/accounts/components/AccountBindings';
import Spinner from '@/components/Spinner';

import validationMixin from '@/utils/validation';
import { required, email } from 'vuelidate/lib/validators';

import EditProfile from '@/views/accounts/components/EditProfileForm';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: { EditProfile, AccountBindings, Spinner },
  mixins: [validationMixin],

  props: {
    copyId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      account: {
        roleId: null,
        email: null,
        profile: {
          type: 'human',
        },
        bindings: { orgs: [] },
      },
      isLoading: false,
      isLoadingBindings: false,
    };
  },

  validations: {
    account: {
      roleId: { required },
      email: { required, email },
    },
  },

  computed: {
    ...mapGetters('ACCOUNTS', ['roles', 'listItem', 'itemBindings']),
  },

  // if we open this modal and there is an account in store
  // means we r currently creating a copy of this user
  async created() {
    if (this.copyId) {
      this.isLoadingBindings = true;
      await this.fetchItem(this.copyId);

      this.account = {
        ...this.listItem(this.copyId),
        profile: {
          type: 'human',
        },
        bindings: this.itemBindings(this.copyId),
        email: null,
      };
      delete this.account.copyId;
      this.isLoadingBindings = false;
    }
  },

  methods: {
    ...mapActions('ACCOUNTS', ['fetchItem', 'createItem']),

    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid || !this.$refs.form.validate()) return;
      this.setServerValidationErrors([]);
      this.isLoading = true;

      try {
        await this.createItem(this.account);
        this.$emit('close');

        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Аккаунт успешно создан',
        });
      } catch (error) {
        if (error?.response?.status === 400) {
          this.setServerValidationErrors(error.response.data.inner, 'account');
        } else {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Произошла ошибка создания аккаунта',
            text: error.message,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
